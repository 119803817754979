import React from 'react';
import {withTranslation} from "react-i18next";

const TwoKProgrammeKey = ({ items, t }) => {

    if (items && items.hasOwnProperty('athlete_level_key')) {
        return (
            <div className="c-programme__key xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-10 offset-1">

                        <h3 className="c-programme__key-title">{t("programme.key.title")}</h3>

                        <div className="c-table xs-mt-20">
                            <div className="c-table__head c-table__head--border-bottom">
                                <div className="c-table__col c-table__col--sixth c-table__col--text-left c-table__col--text-red">{t("programme.key.level-heading")}</div>
                                <div className="c-table__col c-table__col--two-thirds c-table__col--text-left c-table__col--text-red">{t("programme.key.description-heading")}</div>
                                <div className="c-table__col c-table__col--sixth c-table__col--text-left c-table__col--text-red">{t("programme.key.sessions-heading")}</div>
                            </div>
                            {items['athlete_level_key'] && items['athlete_level_key'].map((item, key) => (
                                <div key={key} className="c-table__row c-table__row--border-bottom xs-pt-10 xs-pb-10">
                                    <div className="c-table__col c-table__col--sixth c-table__col--text-bold">{item.level}</div>
                                    <div className="c-table__col c-table__col--two-thirds">{item.description}</div>
                                    <div className="c-table__col c-table__col--sixth">
                                        {item.minimum_number_of_sessions === item.maximumNumberOfSessions ? item.minimumNumberOfSessions :
                                            `${item.minimumNumberOfSessions} - ${item.maximumNumberOfSessions}` }
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>

        );
    }

    return (
        <></>
    );
}

export default withTranslation()(TwoKProgrammeKey);
