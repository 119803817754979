import React from 'react';
import TwoKProgrammeSessions from "../programme-results/component.2k-programme-sessions";
import TwoKProgrammeInstructions from "../programme-results/component.2k-programme-instructions";
import TwoKProgrammeNotes from "../programme-results/component.2k-programme-notes";
import TwoKProgrammeRecovery from "../programme-results/component.2k-programme-recovery";
import TwoKProgrammeSend from "../programme-results/component.2k-programme-send";

const TwoKProgrammeResults = ({ queryParams, programme }) => {
    
    return (
        <>
            <TwoKProgrammeSessions queryParams={queryParams} />
            <TwoKProgrammeInstructions queryParams={queryParams} />
            <TwoKProgrammeNotes />
            <TwoKProgrammeRecovery queryParams={queryParams} />
            <TwoKProgrammeSend programme={programme}/>
        </>
    );
}

export default TwoKProgrammeResults;
