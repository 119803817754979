import { useStaticQuery, graphql } from 'gatsby';

const useTwokProgrammeData = ({ level, weeksTillRace }) => {
    const twokProgrammeData = useStaticQuery(graphql`
        query twokProgrammeData {
            wordpress {
                twokProgramme {
                    level
                    week
                    session_1
                    session_2
                    session_3
                    session_4
                    session_5
                    session_6
                    session_7
                    session_8
                }
            }
        }
    `);
    
    const levelData = twokProgrammeData.wordpress.twokProgramme.filter(x => parseInt(x['level']) === level);
    
    const weekData = levelData.filter((x) => {
        const week = parseInt(x.week);
        if (week >= (27 - weeksTillRace)) {
            return true;
        }
        return false;
    });
    // console.log('WEEK DATA', weekData);
    return weekData;
};

export default useTwokProgrammeData;
