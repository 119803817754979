import React from 'react';
import {withTranslation} from "react-i18next";

const TwoKProgrammeInstructions = ({ queryParams, t }) => {

    const zones = [{
            name: `UT2 - ${t("programme-results.instructions.zone-endurance")}`,
            heartRateReserve1: 65,
            heartRateReserve2: 70,
            power1: 45,
            power2: 60
        }, {
            name: `UT1 - ${t("programme-results.instructions.zone-intense")}`,
            heartRateReserve1: 70,
            heartRateReserve2: 80,
            power1: 60,
            power2: 70
        }, {
            name: `AT - ${t("programme-results.instructions.zone-threshold")}`,
            heartRateReserve1: 80,
            heartRateReserve2: 85,
            power1: 70,
            power2: 80
        }, {
            name: `TR - ${t("programme-results.instructions.zone-transport")}`,
            heartRateReserve1: 85,
            heartRateReserve2: 95,
            power1: 80,
            power2: 105
        }, {
            name: `AN - ${t("programme-results.instructions.zone-max")}`,
            heartRateReserve1: 95,
            heartRateReserve2: 100,
            power1: 105,
            power2: 115
    }];

    const restingHeartRate = queryParams.get('restingHeartRate');
    const maxHeatRate = queryParams.get('maxHeartRate');
    const heartRateReserve = maxHeatRate - restingHeartRate;
    const pace2k = (queryParams.get('current2kTimeMins') * 60) + parseInt(queryParams.get('current2kTimeSecs'));
    const watts = 2.8 / Math.pow(pace2k / 2000, 3);

    const convertTime = (tenthsOfASecond) => {
        const mins = Math.floor(tenthsOfASecond / 600);
        const subsec = tenthsOfASecond - (mins * 600);
        const secs = Math.floor( subsec / 10);
        return mins + ":" + (secs < 10 ? "0" + secs : secs);
    }

    return (
        <div className="c-programme__sessions xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h4>{t("programme-results.instructions.title-a")}<span className="c-programme__instructions-title"> - {t("programme-results.instructions.title-b")}</span></h4>
                    <p>{t("programme-results.instructions.tagline")}</p>
                    <p class="xs-pb-40">{t("programme-results.instructions.sub-title")}</p>

                    <div className="c-programme__pace-guide c-table c-table--bordered">
                        <div className="c-table__head">
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">{t("programme-results.instructions.zone-heading")}</div>
                            <div className="c-table__col c-table__col--sixth  c-table__col--text-left c-table__col--black">{t("programme-results.instructions.heart-heading")}</div>
                            <div className="c-table__col c-table__col--sixth  c-table__col--text-left c-table__col--black">{t("programme-results.instructions.hrr-heading")}</div>
                            <div className="c-table__col c-table__col--sixth  c-table__col--text-left c-table__col--black">{t("programme-results.instructions.pace-heading")}</div>
                            <div className="c-table__col c-table__col--sixth  c-table__col--text-left c-table__col--black">{t("programme-results.instructions.power-heading")}</div>
                        </div>

                        {zones.map(({ name, heartRateReserve1, heartRateReserve2, power1, power2 }) => {

                            const pace1watts = (watts * power1) / 100;
                            const pace1 = Math.round(Math.pow(2.8 / pace1watts, 1 / 3) * 500) * 10;

                            const pace2watts = (watts * power2) / 100;
                            const pace2 = Math.round(Math.pow(2.8 / pace2watts, 1 / 3) * 500) * 10;

                            const heartRate1 = Math.round(((heartRateReserve * heartRateReserve1) / 100) + parseInt(restingHeartRate));
                            const heartRate2 = Math.round(((heartRateReserve * heartRateReserve2) / 100) + parseInt(restingHeartRate));

                            return (
                                <div key={name} className="c-table__row">
                                    <div className="c-table__col c-table__col--third ">{name}</div>
                                    <div className="c-table__col c-table__col--sixth ">{heartRate1} - {heartRate2}</div>
                                    <div className="c-table__col c-table__col--sixth ">{heartRateReserve1} - {heartRateReserve2}</div>
                                    <div className="c-table__col c-table__col--sixth ">{convertTime(pace1)} - {convertTime(pace2)}</div>
                                    <div className="c-table__col c-table__col--sixth ">{power1} - {power2}</div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TwoKProgrammeInstructions);
