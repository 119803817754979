import React, {useContext, useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";

import Button from "../component.button";
import ThemeContext from "../../context/context.theme";

const TwoKProgrammeForm = ({ items, t }) => {
    const theme = useContext(ThemeContext);
    const defaultFormDetails = {
        level: '',
        sessionsPerWeek: '',
        howManyWeeksToRace: '',
        current2kTimeSecs: '',
        current2kTimeMins: '',
        age: '',
        restingHeartRate: '',
        maxHeartRate: ''
    };

    // todo - make default options
    let defaultSessionsPerWeekOptions = [
        `${t("programme.form.session-option")}:`,
        t("programme.form.level-option")
    ];

    const [formDetails, setFormDetails] = useState(defaultFormDetails);
    const [sessionsPerWeekOptions, setSessionsPerWeekOptions] = useState(defaultSessionsPerWeekOptions);

    const handleInputChange = event => {
        const { target } = event;
        const { value, name } = target;

        setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };

    const handleLevelChange = event => {
        // set the default
        handleInputChange(event);

        // change the sessions per week value
        const { target } = event;
        const { value } = target;

        if (value !== '' && items.hasOwnProperty('athlete_level_key')) {
            const min = items['athlete_level_key'][value-1]['minimumNumberOfSessions'];
            const max = items['athlete_level_key'][value-1]['maximumNumberOfSessions'];
            const sessionsPerWeekOptions = ['SESSIONS A WEEK'];
            for (let i = min; i <= max; i++) {
                sessionsPerWeekOptions.push(i);
            }
            setSessionsPerWeekOptions(sessionsPerWeekOptions);
        } else {
            setSessionsPerWeekOptions(defaultSessionsPerWeekOptions);
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
    };

    const getSelectOptions = (min, max) => {
        let items = []
        for(let i = min; i <= max; i++) {
            items.push(i)
        };
        return items;
    };

    return (
        <div className="c-programme__form u-bg--off-white xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h3 className="c-programme__form-title xs-mb-40">{t("programme.form.title")}</h3>

                    <form method="GET">

                        <div className="row c-programme__gutters">
                            <div className="col-sm-2">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="level"
                                            name="level"
                                            value={formDetails.level}
                                            onChange={handleLevelChange}
                                            required
                                        >
                                            <option value=''>{t("programme.form.level-label")}:</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="sessionsPerWeek"
                                            name="sessionsPerWeek"
                                            value={formDetails.sessionsPerWeek}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            {sessionsPerWeekOptions.map((value, index) => (
                                                <option key={index} value={index === 0 ? '' : value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="howManyWeeksToRace"
                                            name="howManyWeeksToRace"
                                            value={formDetails.howManyWeeksToRace}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" key={0}>{t("programme.form.week-label")}:</option>
                                            {getSelectOptions(8, 28).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row c-programme__gutters">
                            <div className="col-sm-4">
                               <div className="c-programme__time">
                                   {t("programme.form.current-title")}:
                               </div>
                            </div>

                            <div className="col-sm-2">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="current2kTimeMins"
                                            name="current2kTimeMins"
                                            value={formDetails.current2kTimeMins}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" key={0}>{t("programme.form.mins-label")}:</option>
                                            {getSelectOptions(5, 12).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-2">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="current2kTimeSecs"
                                            name="current2kTimeSecs"
                                            value={formDetails.current2kTimeSecs}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" key={0}>{t("programme.form.secs-label")}:</option>
                                            {getSelectOptions(0, 59).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="age"
                                            name="age"
                                            value={formDetails.age}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" key={0}>{t("programme.form.age-label")}:</option>
                                            {getSelectOptions(12, 120).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row c-programme__gutters">

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="restingHeartRate"
                                            name="restingHeartRate"
                                            value={formDetails.restingHeartRate}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" key={0}>{t("programme.form.resting-label")}:</option>
                                            {getSelectOptions(40, 120).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <div className="c-input-holder__wrapper">
                                        <select
                                            className="c-input-holder__select"
                                            id="maxHeartRate"
                                            name="maxHeartRate"
                                            value={formDetails.maxHeartRate}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" key={0}>{t("programme.form.maximum-label")}:</option>
                                            {getSelectOptions(80, 220).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row c-programme__gutters">
                            <div className="col-sm-12">
                                <div className="c-programme__submit">
                                    <Button text={t("programme.form.button-label")} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TwoKProgrammeForm);
