import React from 'react';

import TwoKProgrammeForm from "./component.2k-programme-form";
import TwoKProgrammeKey from "./component.2k-programme-key";
import TwoKProgrammeResults from "./component.2k-programme-results";
import Content from "../component.content";

const TwoKProgramme = ({ items, content, programme }) => {
    
    const requiredKeys = [
        'level',
        'sessionsPerWeek',
        'howManyWeeksToRace',
        'current2kTimeSecs',
        'current2kTimeMins',
        'age',
        'restingHeartRate',
        'maxHeartRate'
    ];
    
    const queryParams = typeof window !== `undefined` ? new URLSearchParams(window.location.search) : false;
    
    const hasFilledInForm = () => {
        if (queryParams) {
            const keys = Array.from(queryParams.keys());
            const result = requiredKeys.filter(x => !keys.includes(x));
            return result.length === 0;
        }
    }
    
    if (hasFilledInForm()) {
        return (
            <div className="xs-pt-40 xs-pb-40">
                <div className="container">
                    <div className="c-programme__blocks xs-mt-40">
                        <TwoKProgrammeResults queryParams={queryParams} programme={programme} />
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div className="u-bg--wild-sand xs-pt-40 xs-pb-40">
            <div className="container">
                <div className="c-programme__blocks xs-mt-40">
                    <div className="c-programme__content xs-mb-40 xs-pt-40 xs-pb-40">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <h2 className="c-programme__item-title">{programme.title}</h2>
                                {content &&
                                    <Content content={content} full />
                                }
                            </div>
                        </div>
                    </div>
                    <TwoKProgrammeForm items={items} />
                    <TwoKProgrammeKey items={items} />
                </div>
            </div>
        </div>
    );
}

export default TwoKProgramme;
