import React from 'react';
import {withTranslation} from "react-i18next";

import useTwokProgrammeData from "../../hooks/hook.useTwokProgrammeData";

const TwoKProgrammeSessions = ({ queryParams, t }) => {

    const sessionsPerWeek = parseInt(queryParams.get('sessionsPerWeek'));
    const level = parseInt(queryParams.get('level'));
    const weeksTillRace = parseInt(queryParams.get('howManyWeeksToRace'));
    const sessions = useTwokProgrammeData({ level, weeksTillRace });

    const skipColumns = {
        1: {},
        2: { 3: 3, 4: null },
        3: { 4: 3, 5: null },
        4: { 4: [1, 5], 5: 1, 6: null},
        5: { 6: [1, 7], 7: 1, 8: null}
    }

    const testWeeks = {
        8: [1],
        9: [1, 8],
        10: [1, 8],
        11: [1, 8],
        12: [1, 8],
        13: [1, 8],
        14: [1, 8],
        15: [1, 8],
        16: [1, 8],
        17: [1, 8],
        18: [1, 9],
        19: [1, 9],
        20: [1, 10],
        21: [1, 10],
        22: [1, 11],
        23: [1, 11],
        24: [1, 12],
        25: [1, 12],
        26: [1, 13],
    }

    const sessionData = (week, weekIndex) => {
        const data = [];

        for(let i = 1; i <= 8; i++) {
            if (week['session_' + i] !== "") {

                // This output's TEST columns for those weeks that require it
                if (Array.isArray(testWeeks[weeksTillRace]) && testWeeks[weeksTillRace].includes(weekIndex+1) && i === 1) {
                    data.push('TEST');
                }

                // This handles general skipping of columns for normal weeks
                if (skipColumns[level][sessionsPerWeek] === i || (Array.isArray(skipColumns[level][sessionsPerWeek]) && skipColumns[level][sessionsPerWeek].includes(i))) {
                    continue;
                }

                if (Array.isArray(testWeeks[weeksTillRace]) && testWeeks[weeksTillRace].includes(weekIndex+1)) {

                    // This handles those skipping of columns for TEST WEEKS that have multiple columns to skip
                    if ((Array.isArray(skipColumns[level][sessionsPerWeek]) && skipColumns[level][sessionsPerWeek][0] === (i - 1))) {
                        continue;
                    }
                    // This handles those skipping of columns for TEST WEEKS with single columns to skip
                    if ((skipColumns[level][sessionsPerWeek]+1) === i) {
                        continue;
                    }
                    // This handles those columns without a skipped column
                    if (!skipColumns[level][sessionsPerWeek] && testWeeks[weeksTillRace][0] === i) {
                        continue;
                    }
                }

                data.push(week['session_' + i]);
            }
        }

        return data;
    }

    const SessionRow = ({ session, index }) => (
        <>
            <div className="c-table__col c-table__col--quarter c-table__col--black c-programme__session-col">{t("programme-results.sessions.session-heading")} {index + 1}</div>
            <div className="c-table__col c-table__col--quarter c-table__col--mercury c-programme__session-col">{session}</div>
        </>
    )

    return (
        <div className="c-programme__sessions xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h1 className="c-programme__sessions-title">2000M {weeksTillRace} {t("programme-results.sessions.title")}</h1>
                    <p>{t("programme-results.sessions.tagline-a")} {level}, {sessionsPerWeek} {t("programme-results.sessions.tagline-b")}</p>

                    <h2 className="c-programme__sessions-subtitle">{t("programme-results.sessions.sub-title")}</h2>

                    <div className="row c-programme__gutters">
                        {sessions.map((week, index) => {
                            return (
                                <div key={index} className="col-md-6">
                                    <div className="c-table">
                                        <div className="c-table__head c-table__head--border-bottom-primary xs-mb-5">
                                            <div className="c-table__col c-table__col--text-center c-table__col--text-red">{t("programme-results.sessions.week-heading")} {index + 1}</div>
                                        </div>

                                        <div className="c-table__row">
                                            {sessionData(week, index).map((session, index) => {
                                                return (
                                                    <SessionRow key={index} session={session} index={index} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TwoKProgrammeSessions);
