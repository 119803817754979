import React from 'react';
import {withTranslation} from "react-i18next";

const TwoKProgrammeRecovery = ({ queryParams, t }) => {

    const restingHeartRate = queryParams.get('restingHeartRate');

    return (
        <div className="c-programme__recovery xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h4 className="xs-mb-20">{t("programme-results.recovery.title")}</h4>

                    <p>{t("programme-results.recovery.main-text-a")}</p>

                    <ul className="c-programme__recovery-list">
                        <li>{t("programme-results.recovery.resting-list-item")}</li>
                        <li>{t("programme-results.recovery.warm-up-list-item")} = {restingHeartRate * 2} BPM</li>
                        <li>{t("programme-results.recovery.full-rec-list-item")} = {restingHeartRate * 2} BPM</li>
                        <li>{t("programme-results.recovery.90-rec-list-item")} = {Math.round(parseInt(restingHeartRate) + (restingHeartRate * 1.1))} BPM</li>
                        <li>{t("programme-results.recovery.80-rec-list-item")} = {Math.round(parseInt(restingHeartRate) + (restingHeartRate * 1.2))} BPM</li>
                    </ul>


                    <p>{t("programme-results.recovery.main-text-b")}</p>

                    <p class="xs-pb-0"><span className="c-programme__recovery-important">{t("programme-results.recovery.highlight")}:</span> {t("programme-results.recovery.closing-line")}</p>

                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TwoKProgrammeRecovery);
