import React from 'react';
import {withTranslation} from "react-i18next";

const TwoKProgrammeNotes = ({ t }) => {

    return (
        <div className="c-programme__notes u-bg--mercury xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h4 className="xs-mb-20">{t("programme-results.notes.title")}</h4>

                    <ol className="c-programme__notes-list xs-mb-40">
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-a")}</li>
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-b")}</li>
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-c")}</li>
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-d")}</li>
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-e")}</li>
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-f")}</li>
                        <li className="c-programme__notes-list-item">{t("programme-results.notes.notes-list-item-g")}</li>
                    </ol>

                    <div className="c-table c-table--bordered xs-mb-40">
                        <div className="c-table__head">
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">{t("programme-results.notes.session-heading")}</div>
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">{t("programme-results.notes.warm-up-heading")}</div>
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">{t("programme-results.notes.cool-down-heading")}</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">UT2</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">5-8 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">5-8 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">UT1</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">8-10 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">8-10 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">AT</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">10-12 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">10-12 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">TR</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">12-15 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">12-15 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">AN</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">15-20 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">15-20 min</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default withTranslation()(TwoKProgrammeNotes);
