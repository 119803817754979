import React from 'react';

import ComponentProgrammeParser from "./component.programme-parser";

const Programme = ({ programme, content, blocks }) => (
    <>
        {blocks &&
            <ComponentProgrammeParser blocks={blocks} programme={programme} content={content}/>
        }
    </>
);

export default Programme;
