import React from 'react';

import TwoKProgramme from "./component.2k-programme";

/**
 * Render components
 *
 * Renders the relevant components with the corresponding data for blocks added in the CMS
*/

const components = {
    'acf/interactive-2k-programme': TwoKProgramme
};

const isEmpty = obj => {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
};

/**
 * Makes the data available directly at component.data.
 * Allows us to pass the data directly to the component
 * as we know where it's located.
 *
 * Also avoids error with unquie name not being available
 * when rendering multiple blocks.
 *
 * @param {*} component
 * @returns
 */

const convertACFProps = component => {
    Object.keys(component).forEach(key => {
        // Check if the component is using our acf data structure
        if (key.includes('Fields')) {
            if (component[key].hasOwnProperty('ComponentContainer')) {
                // this resolves the issue with the ComponentContainer on the same level as ComponentData
                if (component[key].ComponentContainer.length !== undefined) {
                    component.data = { items: component[key].ComponentContainer };
                    delete component[key];
                } else if (component[key].ComponentContainer.hasOwnProperty('ComponentData')) {
                    component.data = component[key].ComponentContainer.ComponentData;
                    delete component[key];
                }
            } else {
                // catch all
                component.data = { items: component[key] };
                delete component[key];
            }
        }
    });

    return component;
};

const ComponentProgrammeParser = ({ blocks, content, programme }) => {
    // An object is created for every block, even if that specific block is not returned
    // This will remove anything we don't want to render as a component (empty blocks/blocks without a name)
    if (!blocks) return null;
    blocks = blocks.filter(component => component.name != null);

    if (blocks && blocks.length > 0) {
        const pageComponents = blocks.map((component, index) => {
            if (isEmpty(component)) return null;
            if (!component) return null;

            const componentName = component.name;
            const Component = components[componentName];

            if (!Component) return null;

            component = convertACFProps(component);

            return (
                <Component
                    index={index}
                    key={`component-${index}`}
                    content={content}
                    programme={programme}
                    {...component.data}
                />
            );
        });

        if (pageComponents) {
            return pageComponents;
        }
    }

    return null;
}

export default ComponentProgrammeParser;
